// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"

// custom SCSS styles
import "./src/css/style.scss"

export const onInitialClientRender = () => {
  // wait to init GTM until after React has hydrated in this lifecycle
  const dataLayer = window.dataLayer || []
  dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
}